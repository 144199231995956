$(function() {

    if (document.addEventListener) {
        document.addEventListener("touchstart", function() {}, false);
    } else if (document.attachEvent) {
        document.attachEvent("touchstart", function() {}, false);
    }
    
    $(window).on('resize', function() {
        $(window).trigger('scroll');
    });
    
    svg4everybody({
        polyfill: true
    });
    
    detectTouch();
    
    autosizeTextarea();
    
    checkInputFocus();
    
    initScrollLinks();
    
    initMobileNav();
    
    pageTransitions();
    
    initStickyHeader();
    
    // initReadMore();
    
    // initMagnificPopup();
    
    // initFiltersBox();
    
    // initAos();

});

// ============================================
// INIT STICKY HEADER
// ============================================
function initStickyHeader(){
    
    ScrollTrigger.create({
        // trigger: ".js-c-header",
        // markers: true,
        // start: '56px',
        endTrigger: ".js-c-footer",
        end: "bottom top",
        toggleClass: {targets: ".js-c-header", className: "is-scrolled"}
      });
    
}


// ============================================
// AUTOSIZE TEXTAREA
// ============================================
function autosizeTextarea(){
    autosize($('textarea'));
    autosize.update($('textarea'));
}


// ============================================
// READ MORE
// ============================================

function initReadMore(){
    
    $('.js-foldable-text__trigger').on('click',function(){
        
        var $randomString = $(this).data('random-string');
        
        var $currentReadMore = $('.js-foldable-text[data-random-string="' + $randomString + '"]');
        
        var $readMoreTrigger = $currentReadMore.find('.js-foldable-text__trigger');
        var $readMoreTriggerLabel = $currentReadMore.find('.js-foldable-text__trigger-label');
        var $readMoreHiddenContent = $currentReadMore.find('.js-foldable-text__hidden-content');
        
    
        if($currentReadMore.hasClass('is-opened')){
            
            // close it
            $currentReadMore.removeClass('is-opened');
            $readMoreTrigger.removeClass('is-opened');
            $readMoreTriggerLabel.html($readMoreTriggerLabel.data('more'));
            
            TweenMax.to($readMoreHiddenContent, 0.7, { height: 0, ease: Expo.easeInOut });
            
        }else{
            
            // open it
            $currentReadMore.addClass('is-opened');
            $readMoreTrigger.addClass('is-opened');
            $readMoreTriggerLabel.html($readMoreTriggerLabel.data('less'));
            TweenMax.set($readMoreHiddenContent, { height: 'auto' });
            TweenMax.from($readMoreHiddenContent, 0.7, { height: 0, ease: Expo.easeInOut });
            
        }
        
    });
    
}


// ============================================
// AOS
// ============================================

function initAos() {
    AOS.init({
        startEvent: 'load',
        duration: 1000,
        easing: 'ease-in-out-expo',
        disable: window.innerWidth < 960,
        anchorPlacement: "center-bottom",
        once: true,
    });
}


// ============================================
// MAGNIFIC POPUP
// ============================================

function initMagnificPopup() {

    $('.js-magnific-popup').magnificPopup({
        type: 'iframe',
        removalDelay: 300, //delay removal by X to allow out-animation
        mainClass: 'mfp-fade',
        autoFocusLast: false,
        midClick: true,
        showCloseBtn: false,
        preloader: false,
        fixedContentPos: true,
        fixedBgPos: true,
        callbacks: {

            beforeOpen: function() {
                var classes = this.st.el.attr('data-wrap-classes').split(' ');
                for (i = 0; i < classes.length; i++) {
                    this.st.mainClass = this.st.mainClass + ' ' + classes[i];
                }
            },

            open: function() {
                var mfp = this;
                // remove overflow visible from html (to avoid 'jumping')
                $('html').css('overflow', 'visible');
            }
        }
    });

}



// ============================================
// PAGE TRANSITIONS
// ============================================

var pageTransitionTl = gsap.timeline({ paused: true });
var loadingScreen,
    loadingScreenBg;
    
function pageTransitions() {
    
    $('html').removeClass('is-loading').addClass('is-loaded');
    
    loadingScreen = $('.js-c-loading-screen');
    loadingScreenBg = $('.js-c-loading-screen__bg');
    
    $('a:not([download]):not(.js-dont-follow):not([target="_blank"]):not([href^="#"]):not([href^="tel:"]):not([href^="mailto:"])').on('click', function(e) {

        e.preventDefault();

        var $self = $(this);
        
        if (e.shiftKey || e.ctrlKey || e.metaKey) {

            window.open($self.attr('href'), '_blank');

        } else {
            
            gsap.to('html', { 
                duration: 0.5, 
                autoAlpha: 0, 
                ease: "sine.inOut", 
                onComplete: function(){
                    window.location.href = $self.attr('href');
                } 
            });
            
        }

    });
    
    // if($('html').hasClass('is-home')){
        
    // }else{
        pageTransitionTl.add(animateLoadingScreenBgIn());
        pageTransitionTl.add(putLoadingScreenInTheBack());
        pageTransitionTl.play();
    // }
    
}


function animateLoadingScreenBgIn(){
    
    var tl = gsap.timeline();
    tl.set(loadingScreenBg, {
        autoAlpha: 1
    });
    tl.to(loadingScreenBg, {
        duration: 0.5,
        autoAlpha: 0,
        ease: "sine.inOut"
    });
    return tl;
    
}

function putLoadingScreenInTheBack(){
    
    var tl = gsap.timeline();
    tl.set(loadingScreen, {
        zIndex: -1,
        onComplete: function(){
            // lazysizes trigger adden op alle .js-lazyload objecten
            $('.js-lazyload').addClass('lazyload');
            // luisteren naar wanneer ieder .lazyload object geload wordt en dan een class .lazyloaded op zijn closest .js-c-lazyload zetten
            initFigureLoadingClasses();
        }
    });
    
    return tl;
}



// ============================================
// CHECK INPUT FOCUS
// ============================================

function checkInputFocus() {
    
    $('.js-is-focusable').each(function(){
        if( !$(this).val() ){
            $(this).closest('.js-c-form__field').removeClass('has-focus');
        }
    });
    
    $('.js-is-focusable').focusin(function() {
        $(this).closest('.js-c-form__field').addClass('has-focus');
    });

    $('.js-is-focusable').blur(function() {
        if( !$(this).val() ){
            $(this).closest('.js-c-form__field').removeClass('has-focus');
        }
    });

}



// ============================================
// MOBILE NAV
// ============================================

var $mobileNavTrigger = $('.js-c-mobile-nav-trigger__button'),
    $mobileNavTriggerLabel = $mobileNavTrigger.find('.js-c-mobile-nav-trigger__label'),
    $header = $('.js-c-header'),
    $headerRowMobileNav = $('.js-c-header__row--mobile-nav');

function initMobileNav() {

    $mobileNavTrigger.on('click', function(event) {

        event.preventDefault();

        $mobileNavTrigger.toggleClass('is-active');

        $header.toggleClass('mobile-nav-is-open');

        $headerRowMobileNav.slideToggle(700, 'easeInOutExpo');

        if ($mobileNavTriggerLabel.text().trim() == $mobileNavTriggerLabel.data("default-label")) {

            $mobileNavTriggerLabel.html($mobileNavTriggerLabel.data("close-label"));

        } else {

            $mobileNavTriggerLabel.html($mobileNavTriggerLabel.data("default-label"));

        }

    });

    $(window).resize(function() {

        if ($(window).innerWidth() > 768) {
            removeMobileMenu();
        }

    });

}

function removeMobileMenu() {

    $mobileNavTrigger.removeClass('is-active');
    $mobileNavTriggerLabel.html($mobileNavTriggerLabel.data("default-label"));
    $header.removeClass('mobile-nav-is-open');
    $headerRowMobileNav.slideUp();

}



// ============================================
// FILTERS BOX
// ============================================

function initFiltersBox() {

    // FILTERS BOX CODE

    $('.js-c-filters-box').on('click', function(event) {
        event.stopPropagation();
    });

    $('.js-c-filters-box__trigger').on('click', function(event) {

        if (!$(this).hasClass('is-opened')) {
            closeAllFilters();
        }

        event.stopPropagation();
        event.preventDefault();

        $(this).parent().find('.js-c-filters-box__content').slideToggle(700, 'easeInOutExpo');

        $(this).closest('.js-c-filters-box__trigger').toggleClass('is-opened');

    });

    $('html').on('click', function(event) {

        if (!$(event.target).is('.js-c-filters-box__content')) {
            closeAllFilters();
        }

    });

    $(document).keyup(function(e) {

        if (e.keyCode == 13) {
            closeAllFilters();
        }

        if (e.keyCode == 27) {
            closeAllFilters();
        }

    });

}

function closeAllFilters() {

    $('.js-c-filters-box__trigger.is-opened').removeClass('is-opened');
    $('.js-c-filters-box__content:visible').each(function(e, index) {

        $(this).slideUp();

    });

}



// ============================================
// INIT SCROLL LINKS
// ============================================

function initScrollLinks() {

    $(".js-c-scroll-link").click(function(e) {

        var id = $(this).attr('href');

        var $block = $(id);
        if ($block.length === 0) {
            return;
        }
        e.preventDefault();

        if ($(document).height() - $block.offset().top < $('.js-c-viewport-height').outerHeight()) {

            $("html, body").animate({
                scrollTop: $(document).height() - $('.js-c-viewport-height').outerHeight()
            }, 1500, "easeInOutExpo");

        } else {

            $("html, body").animate({
                scrollTop: $block.offset().top
            }, 1500, "easeInOutExpo");
        }

    });

}



// ============================================
// FIGURE LAZYLOAD CLASSES
// ============================================

function initFigureLoadingClasses() {
    
    $('.lazyload').on('load', function(){
        
        var lazyloadObject = $(this).closest('.js-c-lazyload');
        var imageLoader = lazyloadObject.find('.js-c-lazyload__loader');
        
        var tl = gsap.timeline();
        
        switch(lazyloadObject.data('effect')){
            case "slide-to-bottom":
                
                tl.to(imageLoader, 
                    { 
                        duration: 1.5, 
                        top: 'auto', 
                        ease: "expo.inOut",
                        onComplete: function(){
                            lazyloadObject.addClass('lazyloaded');
                        }
                    }
                );
                
                break;
                
            case "slide-to-right":
                
                tl.to(imageLoader, 
                    { 
                        duration: 1.5, 
                        left: 'auto', 
                        ease: "expo.inOut",
                        onComplete: function(){
                            lazyloadObject.addClass('lazyloaded');
                        }
                    }
                );
                
                break;
                
            default:
                    
                tl.to(lazyloadObject, 
                    { 
                        duration: .7, 
                        autoAlpha: 1,
                        ease: "expo.inOut",
                        onComplete: function(){
                            lazyloadObject.addClass('lazyloaded');
                        }
                    }
                );
                
        }
        
        
    });

}



// ============================================
// DETECT TOUCH
// ============================================

function detectTouch() {
    if(Modernizr.touchevents){
        $('body').addClass('touch');
        $('.js-check-touch').addClass('touch').removeClass('no-touch');
        window.touch = true;
    }
    // window.addEventListener('touchstart', function onFirstTouch() {
    //     $('body').addClass('touch');
    //     $('.js-check-touch').addClass('touch').removeClass('no-touch');
    //     window.touch = true;
    //     // we only need to know once that a human touched the screen, so we can stop listening now
    //     window.removeEventListener('touchstart', onFirstTouch, false);
    // }, false);
    
}



// ============================================
// STICKY KIT
// ============================================

function stick(child, parent, detachWidth) {

    $(window).on('resize', function() {

        if (($(window).width() + window.getScrollbarWidth()) < detachWidth) {

            child.trigger("sticky_kit:detach");

        } else {
            makeSticky();
        }

    });

    $(window).trigger('resize');

    function makeSticky() {

        child.stick_in_parent({
            parent: parent,
            offset_top: 16
        });

    }
}



// ============================================
// GET DOC HEIGHT
// ============================================

function getDocHeight() {
    var D = document;
    return Math.max(
        D.body.scrollHeight, D.documentElement.scrollHeight,
        D.body.offsetHeight, D.documentElement.offsetHeight,
        D.body.clientHeight, D.documentElement.clientHeight
    );
}



// ============================================
// SETCOOKIE
// ============================================

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


